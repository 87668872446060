// hooks
import React from "react";

// components
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import QR from "./QR";

// libs
import JsBarcode from "jsbarcode";

// assets
import BrandLogo from "../../assets/images/tulus-logo.png";
import JNELogo from "../../assets/images/JNE_GRAY.png";
import SAPLogo from "../../assets/images/SAP_GRAY.png";
import SICEPATLogo from "../../assets/images/SICEPAT_GRAY.png";

const couriers = [
  { name: "jne", logo: JNELogo },
  { name: "sicepat", logo: SICEPATLogo },
  { name: "sap", logo: SAPLogo },
];

const PDF = ({ pdfData }) => {
  const getCourierLogo = (name) => {
    const courier = couriers.find((courier) => courier.name.toLowerCase() === name.toLowerCase());
    return courier.logo;
  };

  // will retun blob img
  const getBarcode = (data) => {
    let canvas;
    canvas = document.createElement("canvas");
    JsBarcode(canvas, data, {
      displayValue: false,
    });
    const barcode = canvas.toDataURL();
    return barcode;
  };

  console.log(pdfData);
  return (
    <Document>
      {pdfData
        ? pdfData.map((item, index) => (
            <Page key={index} size="A6" style={{ padding: "20px", fontSize: "6px" }}>
              <View style={{ display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", paddingBottom: "6px" }}>
                <Image style={{ width: "40px" }} src={BrandLogo} />
                <Text style={{ fontSize: "14px" }}></Text>
                <Image style={{ width: "40px", textTransform: "uppercase" }} src={getCourierLogo(item.shipping_info.courier_id.name)} />
              </View>

              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View style={{ width: "50%" }}>
                  <QR value={item.transaction_id} />
                  <Text>{item.transaction_id}</Text>
                </View>

                <View style={{ width: "50%", display: "flex", textAlign: "center", flexShrink: "1" }}>
                  <View>
                    <Text style={{ paddingBottom: "7px" }}>
                      {item.shipping_info.courier_id.name} - {item.shipping_info.type}
                    </Text>
                    <Text>{item.total_weight} kg</Text>
                  </View>
                  {+item.shipping_info.insurance_price > 0 ? (
                    <View>
                      <Text style={{ paddingBottom: "2px", marginTop: "5px" }}>Asuransi</Text>
                      <Text>YA</Text>
                    </View>
                  ) : null}
                  {/* {item.insurance_price > 0 ? (
                    <View>
                      <Text style={{ paddingBottom: "2px", marginTop: "5px" }}>Asuransi</Text>
                      {item.insurance ? <Text>{formatRupiah(item.insurance_price)}</Text> : <Text>{formatRupiah(0)}</Text>}
                    </View>
                  ) : null} */}
                </View>
              </View>

              <View style={{ display: "flex", width: "100%", justifyContent: "space-between", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View style={{ display: "flex", transform: "translateX(65% 0)" }}>
                  <Image style={{ width: "120px", height: "40px" }} src={getBarcode(item.resi || "delete")} />
                </View>
                <Text style={{ textAlign: "center" }}>{item.resi}</Text>
              </View>
              {/* <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View style={{ width: "60%" }}>
                  <View style={{ paddingBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Text>PENGIRIM :</Text>
                    <Text style={{ paddingRight: "5px" }}>TULUS</Text>
                  </View>
                  <Text>{item.shipper_info.shipper_name}</Text>
                </View>
                {item.cod && (
                  <View style={{ width: "40%", textAlign: "center", borderLeft: "1px solid black" }}>
                    <Text style={{ fontWeight: "bold", paddingBottom: "2px" }}>COD</Text>
                    <Text style={{ paddingBottom: "7px" }}>{formatRupiah(item.cod_price)}</Text>
                  </View>
                )}
              </View> */}
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View>
                  <View style={{ paddingBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Text style={{ width: "50%" }}>PENERIMA :</Text>
                    <Text style={{ textAlign: "right", width: "50%" }}>{item.shipping_address.receiver_code}</Text>
                  </View>
                  <Text style={{ paddingBottom: "5px" }}>{item?.shipping_address?.user_name || item?.user?.name || item.guest_name}</Text>
                  <Text style={{ paddingBottom: "5px" }}>{item.shipping_address.address}</Text>
                  <Text style={{ paddingBottom: "5px" }}>
                    {item.shipping_address.province} {item.shipping_address.city} {item.shipping_address.district} {item.shipping_address.urban} {item.shipping_address.postal_code}
                  </Text>
                  <Text>{item.shipping_address.phone_number}</Text>
                </View>
              </View>
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row", borderBottom: "1px solid black", padding: "8px 0" }}>
                <View>
                  <Text style={{ paddingBottom: "5px" }}>BARANG :</Text>
                  {item.detail.map((prod, idx) => (
                    <Text style={{ paddingBottom: "5px", fontSize: "7px" }}>
                      {console.log(prod)}
                      {prod.qty}x - {prod.product.name} {prod.variant_index > -1 ? <Text>{prod.product.variant_detail[prod.variant_index].variant.join(", ")}</Text> : null}
                    </Text>
                  ))}
                  {item.freebies_product.length ? <Text style={{ paddingBottom: "5px", paddingTop: "6px" }}>FREEBIES :</Text> : null}
                  {item.freebies_product.map((prod, idx) => (
                    <Text style={{ paddingBottom: "5px", fontSize: "7px" }}>1x - {prod.name}</Text>
                  ))}
                  {item.extra.length ? <Text style={{ paddingBottom: "5px", paddingTop: "6px" }}>EXTRA :</Text> : null}
                  {item.extra.map((extra, idx) => (
                    <Text style={{ paddingBottom: "5px", fontSize: "7px" }}>{extra?.gift_id?.content}</Text>
                  ))}
                </View>
              </View>

              {item.user_notes.length ? <Text style={{ paddingBottom: "5px", paddingTop: "6px" }}>NOTES :</Text> : null}
              {item.user_notes.map((user, idx) => (
                <Text style={{ paddingBottom: "5px" }}>{user.note}</Text>
              ))}
            </Page>
          ))
        : ""}
    </Document>
  );
};

export default PDF;
